<template>
	<transition-fade v-if="currentQuestion" mode="out-in">
		<single-question v-if="!isFinal" :key="currentQuestion?.id" :question="currentQuestion" />

		<final-result v-else :question="currentQuestion" />
	</transition-fade>
</template>

<script lang="ts">
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { computed, defineComponent, onUnmounted, PropType, watch } from 'vue';

import FinalResult from '@/components/questions/FinalResult.vue';
import TransitionFade from '@/components/transitions/TransitionFade.vue';
import { questionsGetters, questionsMutations } from '@/store/modules/questions';
import { enumValidator } from '@/tools/helpers';

import SingleQuestion from './SingleQuestion.vue';

gsap.registerPlugin(ScrollToPlugin);

export default defineComponent({
	components: {
		SingleQuestion,
		FinalResult,
		TransitionFade
	},

	props: {
		type: {
			type: String as PropType<'cat' | 'dog'>,
			validator: enumValidator<'cat' | 'dog'>(['cat', 'dog']),
			required: true
		}
	},

	setup(props) {
		const currentQuestion = computed(questionsGetters.currentQuestion);

		questionsMutations.setQuestionnaireType(props.type);
		questionsMutations.restoreFromLocalStorage();

		watch(currentQuestion, () => {
			gsap.to(window, {
				duration: 1,
				ease: 'power3.out',
				scrollTo: { y: 0 }
			});
		});

		onUnmounted(() => {
			questionsMutations.resetQuestionnaire();
		});

		return {
			currentQuestion,

			isFinal: computed(() => currentQuestion.value?.type === 'email')
		};
	}
});
</script>
