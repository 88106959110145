
import { computed, defineComponent, PropType } from 'vue';

import { AnswerValue } from '@/models/AnswerModel';
import {
	BooleanQuestion as BooleanQuestionType,
	DateQuestion as DateQuestionType,
	QuestionType
} from '@/models/QuestionModel';
import { questionsMutations } from '@/store/modules/questions';

import BooleanQuestion from './BooleanQuestion.vue';
import DateQuestion from './DateQuestion.vue';
import QuestionStructure from './QuestionStructure.vue';

type Question = BooleanQuestionType | DateQuestionType;

export default defineComponent({
	components: {
		QuestionStructure,
		BooleanQuestion,
		DateQuestion
	},

	props: {
		question: {
			type: Object as PropType<Question>,
			required: true
		}
	},

	setup(props) {
		const headline = computed(() => props.question.title);
		const subHeadline = computed(() => props.question.subTitle);

		return {
			headline,
			subHeadline,
			isQuestion: computed(() => (type: QuestionType) => props.question.type === type)
		};
	},

	methods: {
		onAnswerSelected(answer: AnswerValue) {
			questionsMutations.setAnswer({ id: this.question.id, value: answer });
			questionsMutations.setNextCurrent();
		}
	}
});
