<template>
	<form-root @submit="onSubmitDate">
		<form-datepicker
			name="callendar"
			class="date-question"
			rules="date-required:@callendar_optional"
		>
			<template v-if="isOptional" #footer>
				<form-checkbox single bigger border name="callendar_optional" :options="checkboxOptions" />
			</template>

			<template #bottom>
				<base-button type="submit" uppercase class="date-question__submit">
					Dalej
				</base-button>
			</template>
		</form-datepicker>
	</form-root>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, watch } from 'vue';

import { useFormContext } from '@/common/validation';
import { DateQuestion } from '@/models/QuestionModel';
import { questionsGetters } from '@/store/modules/questions';

interface DateForm {
	callendar?: Date;
	callendar_optional?: boolean;
}

export default defineComponent({
	props: {
		question: {
			type: Object as PropType<DateQuestion>,
			required: true
		}
	},

	emits: ['date-selected'],

	setup(props, { emit }) {
		const questionData = computed(() => props.question.data);
		const savedAnswers = computed(questionsGetters.answersMap);
		const formContext = useFormContext();
		const formValues = computed(() => (formContext.value?.values ?? {}) as DateForm);

		onMounted(() => {
			const answer = savedAnswers.value[props.question.id]?.answer;

			if (answer) {
				if (answer === true) {
					formContext.value?.setFieldValue('callendar_optional', true);
				} else {
					formContext.value?.setFieldValue('callendar', answer);
				}
			}
		});

		watch(
			() => formValues.value.callendar_optional,
			(curr, prev) => {
				if (prev !== undefined) {
					formContext.value?.validate();
				}

				if (curr === true) {
					formContext.value?.setFieldValue('callendar', null);
				}
			}
		);

		watch(
			() => formValues.value.callendar,
			callendar => {
				if (callendar) {
					formContext.value?.setFieldValue('callendar_optional', false);
				}
			}
		);

		return {
			formContext,

			isOptional: computed(() => props.question.data.optional),
			checkboxOptions: [
				{ label: questionData.value.optional ? questionData.value.optionalText : '', value: true }
			],

			onSubmitDate: () => {
				const toEmit = formValues.value.callendar_optional ? true : formValues.value.callendar;

				emit('date-selected', toEmit);
			}
		};
	}
});
</script>

<style lang="scss">
.date-question {
	&__submit {
		margin: 4rem 0 0;

		@include max-desktop {
			margin: 30px 0 0;
		}

		@include tablet-portrait {
			margin: 40px 0 0;
		}

		@include max-mobile {
			margin-top: 18px;
		}
	}
}
</style>
