import { defineComponent, PropType } from '@vue/runtime-core';

import { enumValidator } from '@/tools/helpers';

export type TransitionMode = 'in-out' | 'out-in';

/**
 * TODO: more extend it, so every custom transition can use it
 */
export function mixTransition(name = '') {
	return defineComponent({
		props: {
			silent: {
				type: Boolean
			},

			mode: {
				type: String as PropType<TransitionMode | ''>,
				default: '',
				validator: enumValidator<TransitionMode | ''>(['in-out', 'out-in', ''])
			},

			css: {
				type: Boolean,
				default: true
			},

			duration: {
				type: Object as PropType<{ in?: number; out?: number }>,
				default: null
			}
		},

		computed: {
			name(): string {
				return this.silent ? '_non-existing' : name;
			}
		}
	});
}
