<template>
	<div class="final-result__disclaimer">
		<p v-if="isDog">
			Drontal Dog Flavour 150/144/50mg tabletki febantel/pyrantelu embonian/prazykwantel • Wskazania
			lecznicze: Zwalczanie inwazji mieszanych, wywoływanych przez nicienie i tasiemce następujących
			gatunków: Robaki obłe: Glisty (postać dorosła i późna niedojrzała):
			<em>Toxocara canis</em>
			,
			<em>Toxascaris leonina</em>
			• Tęgoryjce (postać dorosła):
			<em>Uncinaria stenocephala</em>
			,
			<em>Ancylostoma caninum</em>
			• Włosogłówki (postać dorosła):
			<em>Trichuris vulpis</em>
			• Tasiemce (postać dorosła i późna niedojrzała):
			<em>Echinococus granulosus</em>
			,
			<em>Echinococcus multilocularis</em>
			,
			<em>Dipylidium caninum</em>
			,
			<em>Taenia spp.</em>
			,
			<em>Mesocestoides spp.</em>
			Zwalczanie inwazji pierwotniaków Giardia spp. u szczeniąt i dorosłych psów.• Przeciwwskazania:
			Nie stosować w przypadku nadwrażliwości na substancję czynną lub na dowolną substancję
			pomocniczą. Nie stosować w 1. i 2. trymestrze ciąży. • Podmiot odpowiedzialny: Vetoquinol S.A.
			Francja
			<strong>• Przed użyciem zapoznaj się z treścią ulotki dołączonej do opakowania.</strong>
			<br />
			<br />
			Drontal Plus Flavour 35 kg tabletki dla psów, 525 mg/504 mg/175 mg febantel/pyrantelu
			embonian/prazykwantel • Wskazania lecznicze: Zwalczanie inwazji nicieni i tasiemców przewodu
			pokarmowego psów, takich jak:
			<em>Toxocara canis</em>
			,
			<em>Toxascaris leonina</em>
			,
			<em>Uncinaria stenocephala</em>
			,
			<em>Ancylostoma caninum</em>
			,
			<em>Trichuris vulpis</em>
			,
			<em>Taenia spp.</em>
			,
			<em>Multiceps multiceps</em>
			,
			<em>Dipylidium caninum</em>
			,
			<em>Mesocestoides spp.</em>
			,
			<em>Echinococcus granulosus</em>
			,
			<em>Echinococcus multilocularis</em>
			. Zwalczanie inwazji pierwotniaków Giardia spp. • Przeciwwskazania: Nie stosować u szczeniąt
			poniżej 2. tygodnia życia. Nie stosować u suk w pierwszych dwóch trymestrach ciąży. Nie
			stosować łącznie z produktami zawierającymi lewamizol, morantel, piperazynę, a także
			dietylkarbamazol. • Podmiot odpowiedzialny: Vetoquinol S.A. Francja.
			<strong>Przed użyciem zapoznaj się z treścią ulotki dołączonej do opakowania.</strong>
		</p>
		<p v-if="isKitten">
			Dronspot 30 mg/7,5 mg roztwór do nakrapiania dla małych kotów • Dronspot 60 mg/15 mg roztwór
			do nakrapiania dla średnich kotów • Dronspot 96 mg/24 mg roztwór do nakrapiania dla dużych
			kotów • prazykwantel/emodepsyd • Podmiot odpowiedzialny: Bayer Animal Health GmbH, Niemcy •
			Zawartość substancji czynnych i innych substancji • Każda pipetka 0,35 ml / 0,70 ml / 1,12 ml
			zawiera odpowiednio: Substancje czynne: Prazykwantel 30 mg / 60 mg / 96 mg, Emodepsyd 7,5 mg /
			15 mg / 24 mg, Substancje pomocnicze: Butylohydroksyanizol (E320) 1,89 mg / 3,78 mg / 6,05 mg
			• Wskazania lecznicze: Leczenie mieszanych inwazji pasożytniczych u kotów spowodowanych przez
			następujące gatunki robaków obłych i robaków płaskich: Robaki obłe (nicienie): Toxocara cati
			(postaci dojrzałe, niedojrzałe, stadia larwalne L4 i L3),
			<em>Toxocara cati</em>
			(stadia larwalne L3) – leczenie kotek w okresie późnej ciąży w celu zapobiegania przeniesieniu
			drogą laktogenną na potomstwo,
			<em>Toxascaris leonina</em>
			(postaci dojrzałe, niedojrzałe i stadia larwalne L4),
			<em>Ancylostoma tubaeforme</em>
			(postaci dojrzałe, niedojrzałe i stadia larwalne L4). Robaki płaskie (tasiemce):
			<em>Dipylidium caninum</em>
			(postaci dojrzałe i niedojrzałe),
			<em>Taenia taeniaeformis</em>
			(postaci dojrzałe),
			<em>Echinococcus multilocularis</em>
			(postaci dojrzałe) • Przeciwwskazania: Nie stosować u kociąt poniżej 8 tygodnia życia lub
			ważących poniżej 0,5 kg. Nie stosować w przypadkach znanej nadwrażliwości na substancjeczynne
			lub na dowolną substancję pomocniczą
			<strong>• Przed użyciem zapoznaj się z treścią ulotki dołączonej do opakowania.</strong>
			<br />
			<strong>Drontal, 230 mg/20 mg tabletki powlekane dla kotów •</strong>
			Jedna tabletka zawiera: pyrantelu embonian 230 mg; prazikwantel 20 mg • Wskazania lecznicze:
			Zwalczanie inwazji nicieni i tasiemców przewodu pokarmowego kotów, takich jak:
			<em>Toxocara cati</em>
			,
			<em>Ancylostoma tubaeforme</em>
			,
			<em>Ancylostoma braziliense</em>
			,
			<em>Echinococcus multilocularis</em>
			,
			<em>Dipylidium caninum</em>
			,
			<em>Taenia taenieformis</em>
			,
			<em>Mesocestoides spp.</em>
			,
			<em>Joyeuxiella spp.</em>
			• Przeciwwskazania: Nie stosować u kociąt przed ukończeniem trzeciego tygodnia życia. Nie
			stosować u kotek w pierwszych dwóch trymestrach ciąży. • Podmiot odpowiedzialny: Vetoquinol
			S.A. Francja.
			<strong>Przed użyciem zapoznaj się z treścią ulotki dołączonej do opakowania.</strong>
		</p>
		<p v-if="isNormalCat">
			Dronspot 30 mg/7,5 mg roztwór do nakrapiania dla małych kotów • Dronspot 60 mg/15 mg roztwór
			do nakrapiania dla średnich kotów • Dronspot 96 mg/24 mg roztwór do nakrapiania dla dużych
			kotów • prazykwantel/emodepsyd • Podmiot odpowiedzialny: Bayer Animal Health GmbH, Niemcy •
			Zawartość substancji czynnych i innych substancji • Każda pipetka 0,35 ml / 0,70 ml / 1,12 ml
			zawiera odpowiednio: Substancje czynne: Prazykwantel 30 mg / 60 mg / 96 mg, Emodepsyd 7,5 mg /
			15 mg / 24 mg, Substancje pomocnicze: Butylohydroksyanizol (E320) 1,89 mg / 3,78 mg / 6,05 mg
			• Wskazania lecznicze: Leczenie mieszanych inwazji pasożytniczych u kotów spowodowanych przez
			następujące gatunki robaków obłych i robaków płaskich: Robaki obłe (nicienie):
			<em>Toxocara cati</em>
			(postaci dojrzałe, niedojrzałe, stadia larwalne L4 i L3),
			<em>Toxocara cati</em>
			(stadia larwalne L3) – leczenie kotek w okresie późnej ciąży w celu zapobiegania przeniesieniu
			drogą laktogenną na potomstwo,
			<em>Toxascaris leonina</em>
			(postaci dojrzałe, niedojrzałe i stadia larwalne L4),
			<em>Ancylostoma tubaeforme</em>
			(postaci dojrzałe, niedojrzałe i stadia larwalne L4). Robaki płaskie (tasiemce):
			<em>Dipylidium caninum</em>
			(postaci dojrzałe i niedojrzałe),
			<em>Taenia taeniaeformis</em>
			(postaci dojrzałe),
			<em>Echinococcus multilocularis</em>
			(postaci dojrzałe) • Przeciwwskazania: Nie stosować u kociąt poniżej 8 tygodnia życia lub
			ważących poniżej 0,5 kg. Nie stosować w przypadkach znanej nadwrażliwości na substancjeczynne
			lub na dowolną substancję pomocniczą
			<strong>• Przed użyciem zapoznaj się z treścią ulotki dołączonej do opakowania.</strong>
		</p>
		<p v-if="isCatOnExhibition">
			Dronspot 30 mg/7,5 mg roztwór do nakrapiania dla małych kotów • Dronspot 60 mg/15 mg roztwór
			do nakrapiania dla średnich kotów • Dronspot 96 mg/24 mg roztwór do nakrapiania dla dużych
			kotów • prazykwantel/emodepsyd • Zawartość substancji czynnych i innych substancji • Każda
			pipetka 0,35 ml / 0,70 ml / 1,12 ml zawiera odpowiednio: Substancje czynne: Prazykwantel 30 mg
			/ 60 mg / 96 mg, Emodepsyd 7,5 mg / 15 mg / 24 mg, Substancje pomocnicze: Butylohydroksyanizol
			(E320) 1,89 mg / 3,78 mg / 6,05 mg • Wskazania lecznicze: Leczenie mieszanych inwazji
			pasożytniczych u kotów spowodowanych przez następujące gatunki robaków obłych i robaków
			płaskich: Robaki obłe (nicienie):
			<em>Toxocara cati</em>
			(postaci dojrzałe, niedojrzałe, stadia larwalne L4 i L3),
			<em>Toxocara cati</em>
			(stadia larwalne L3) – leczenie kotek w okresie późnej ciąży w celu zapobiegania przeniesieniu
			drogą laktogenną na potomstwo,
			<em>Toxascaris leonina</em>
			(postaci dojrzałe, niedojrzałe i stadia larwalne L4),
			<em>Ancylostoma tubaeforme</em>
			(postaci dojrzałe, niedojrzałe i stadia larwalne L4). Robaki płaskie (tasiemce):
			<em>Dipylidium caninum</em>
			(postaci dojrzałe i niedojrzałe),
			<em>Taenia taeniaeformis</em>
			(postaci dojrzałe),
			<em>Echinococcus multilocularis</em>
			(postaci dojrzałe) • Przeciwwskazania: Nie stosować u kociąt poniżej 8 tygodnia życia lub
			ważących poniżej 0,5 kg. Nie stosować w przypadkach znanej nadwrażliwości na substancje czynne
			lub na dowolną substancję pomocniczą • Podmiot odpowiedzialny: Vetoquinol S.A., Francja
			<strong>• Przed użyciem zapoznaj się z treścią ulotki dołączonej do opakowania.</strong>
		</p>
	</div>
</template>

<script>
export default {
	props: {
		animalType: {
			type: String,
			required: true
		},
		catType: {
			type: String,
			default: '',
			required: false
		}
	},
	computed: {
		isDog() {
			return this.animalType === 'dog';
		},
		isKitten() {
			return !this.isDog && this.catType === 'kitten';
		},
		isCatOnExhibition() {
			return !this.isDog && this.catType === 'exhibition';
		},
		isNormalCat() {
			return !this.isDog && !this.isKitten && !this.isCatOnExhibition;
		}
	}
};
</script>
