
import dayjs, { Dayjs } from 'dayjs';
import queryString from 'query-string';
import {
	computed,
	defineComponent,
	nextTick,
	onMounted,
	PropType,
	reactive,
	toRefs,
	watch
} from 'vue';

import { $isLoading } from '@/common/loading';
import { changeErrorMessage, useFormContext } from '@/common/validation';
import Spinner from '@/components/base/spinner/Spinner.vue';
import { EmailPayload } from '@/models/AnswerModel';
import { questionsActions } from '@/store/modules/questions';
import { questionsState } from '@/store/modules/questions';
import { checked } from '@/tools/rules';
import { AnyObject, FinalDates } from '@/tools/types/basic';

import TransitionHeight from '../transitions/TransitionHeight.vue';

interface MailForm {
	email?: string;
	callendar?: boolean;
	mailchimp?: boolean;
}

export default defineComponent({
	components: {
		Spinner,
		TransitionHeight
	},

	props: {
		nextDates: {
			type: Array as PropType<FinalDates[]>,
			default: () => []
		},
		details: {
			type: String,
			default: ''
		},
		recommendations: {
			type: String,
			default: ''
		},
		dates: {
			type: Array as PropType<AnyObject[]>,
			default: () => []
		}
	},

	emits: ['submit', 'update:errors'],

	setup(props) {
		const formContext = useFormContext();
		const formValues = computed(() => (formContext.value?.values ?? {}) as MailForm);

		const isSendingEmail = computed(() => $isLoading('questions/sendEmail'));
		const isSubscribingToMailchimp = computed(() => $isLoading('questions/subscribeMailchimp'));
		const isLoading = computed(() =>
			$isLoading(['questions/sendEmail', 'questions/subscribeMailchimp'])
		);

		const compData = reactive({
			buttonText: 'WYŚLIJ',
			hasError: false,
			isLinkHovered: false,
			wasSubmitted: false
		});

		const currentAnimal = computed(questionsState.type);
		const currentAnimalForMail = computed(() => {
			if (currentAnimal.value === 'cat') {
				return 'kotów';
			} else if (currentAnimal.value === 'dog') {
				return 'psów';
			} else {
				return '';
			}
		});

		onMounted(async () => {
			await nextTick();

			formContext.value?.getFieldContext('callendar')?.resetField();
			formContext.value?.getFieldContext('mailchimp')?.resetField();
		});

		watch(
			() => isLoading.value,
			value => {
				if (!value) {
					setTimeout(() => {
						compData.wasSubmitted = true;

						setTimeout(() => {
							compData.wasSubmitted = false;
						}, 5000);

						resetAfterSending();
					}, 1500);
				}
			}
		);

		const resetAfterSending = (): void => {
			compData.buttonText = 'WYŚLIJ';
			compData.hasError = false;
			formContext.value?.resetForm();
		};

		const formatDates = (dates: AnyObject[]) => {
			const datesToReturn: any[] = [];

			dates.forEach(dateColumn => {
				const column: any = {
					headline: dateColumn.headline.replaceAll('<br/>', ''),
					date: []
				};

				dateColumn.dates.forEach((date: Dayjs) => {
					const dateAsString: string = dayjs(date).format('D.MM.YYYY');
					column.date.push(dateAsString);
				});

				datesToReturn.push(column);
			});

			return datesToReturn;
		};

		const onSubmit = () => {
			const isCallendarSelected = !!formValues.value.callendar;
			const isMailchimpSelected = !!formValues.value.mailchimp;

			if (isCallendarSelected) {
				sendEmail();
			}

			if (isMailchimpSelected) {
				subscribeMailchimp();
			}
		};

		const sendEmail = async () => {
			const emailData: EmailPayload = {
				email: formValues?.value?.email || '',
				content: {
					recommendations: props.recommendations,
					animalType: currentAnimalForMail.value,
					dates: formatDates(props.dates)
				}
			};

			try {
				const response = await questionsActions.sendEmail(emailData);
				console.log(response);
			} catch (err) {
				console.error(err);
			}
		};

		const subscribeMailchimp = async () => {
			const sharedData = {
				u: 'd1fb0520ea952a9212888ac73',
				id: '4b15afa467',
				EMAIL: formValues.value.email,
				MMERGE6: 'odrobaczanie.szczesliwyzwierzak.pl'
			};
			const animalData = currentAnimal.value === 'dog' ? { MMERGE1: 'PIES' } : { MMERGE1: 'KOT' };
			const mailChimpData = queryString.stringify({
				...sharedData,
				...animalData
			});

			try {
				const response = await questionsActions.subscribeMailchimp(mailChimpData);
				console.log(response);
			} catch (err) {
				console.error(err);
			}
		};

		return {
			...toRefs(compData),

			callendarOption: {
				label:
					'„Na podstawie art. 7 ust. 1 RODO oświadczam, iż wyrażam zgodę na przetwarzanie przez administratora, którym jest Vetoquinol Biowet Sp. z o.o. w Gorzowie Wielkopolskim moich danych osobowych w celu nawiązania kontaktu. Powyższa zgoda została wyrażona dobrowolnie zgodnie z art. 4 pkt 11 RODO."',
				value: 'callendar'
			},

			mailchimpOption: {
				label: `„Na podstawie art. 7 ust. 1 RODO oświadczam, iż wyrażam zgodę na przetwarzanie przez
						administratora, którym jest Vetoquinol Biowet Sp. z o.o. w Gorzowie Wlkp. moich danych
						osobowych w celu zapisania się do newslettera. Powyższa zgoda została wyrażona
						dobrowolnie zgodnie z art. 4 pkt 11 RODO.”*
                        <br />
                        Z naszego newslettera możesz zrezygnować w każdej chwili, poprzez kliknięcie w link,
                        który znajduje się w stopce każdej wiadomości e-mail, którą od nas otrzymasz.
                        <br />
                        Informacje o naszej polityce prywatności i danych osobowych znajdziesz 
						na stronie: https://www.vetoquinol.pl/polityka-prywatnosci.
                        <br />
                        Aby zapewnić najwyższą jakość usług, do wysyłki newslettera używamy platformy Mailchimp.
                        Poprzez kliknięcie "Zapisz się", wyrażasz zgodę na przekazanie swoich danych temu
                        subprocesorowi.
                        <br />
                        Polityka prywatności Mailchimp znajduje się <a class="email-subscribe__mailchimp-privacy" href="https://mailchimp.com/legal/privacy" target="_blank" >tutaj</a>.`,
				value: 'mailchimp'
			},

			callendarRequired: changeErrorMessage(checked, 'Zgoda jest wymagana'),

			onSubmit,
			isLoading,
			isSendingEmail,
			isSubscribingToMailchimp,
			currentAnimal
		};
	},

	mounted() {
		const mailchimpLink = (this.$el as HTMLElement).querySelector(
			'.email-subscribe__mailchimp-privacy'
		);

		mailchimpLink?.addEventListener('mouseenter', this.onMouseenter);
		mailchimpLink?.addEventListener('mouseleave', this.onMouseleave);
	},

	beforeUnmount() {
		const mailchimpLink = (this.$el as HTMLElement).querySelector(
			'.email-subscribe__mailchimp-privacy'
		);

		mailchimpLink?.removeEventListener('mouseenter', this.onMouseenter);
		mailchimpLink?.removeEventListener('mouseleave', this.onMouseleave);
	},

	methods: {
		onMouseenter() {
			this.isLinkHovered = true;
		},

		onMouseleave() {
			this.isLinkHovered = false;
		}
	}
});
