
// import { gsap, Power3 } from 'gsap/all';
// import { mixTransition } from '@/mixins/factory';
import { defineComponent } from 'vue';

// css transitions are much more stable when "clicking insanely on transitioning element" <---
export default defineComponent({
	// 	mixins: [mixTransition('')],
	// 	setup() {
	// 		const animationDefaults: gsap.TweenVars = {
	// 			duration: 0.6,
	// 			ease: Power3.easeOut
	// 		};
	// 		return {
	// 			onEnter: (el: HTMLElement, done: () => void) => {
	// 				// gsap.killTweensOf(el);
	// 				gsap.fromTo(el, { autoAlpha: 0 }, { ...animationDefaults, autoAlpha: 1, onComplete: done });
	// 			},
	// 			onLeave: (el: HTMLElement, done: () => void) => {
	// 				gsap.fromTo(el, { autoAlpha: 1 }, { ...animationDefaults, autoAlpha: 0, onComplete: done });
	// 			}
	// 		};
	// 	}
});
