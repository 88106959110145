
import dayjs, { Dayjs } from 'dayjs';
import { computed, defineComponent, PropType, reactive, ref, toRefs } from 'vue';

import FinalResultLegals from '@/components/questions/FinalResultLegals.vue';
import { useRedirectOnIdle } from '@/composables';
import { EmailQuestion } from '@/models/QuestionModel';
import { questionsGetters, questionsState } from '@/store/modules/questions';
import { AnyObject } from '@/tools/types';

import FinalDates from '../final/FinalDates.vue';
import EmailSubscribe from './EmailSubscribe.vue';
import QuestionStructure from './QuestionStructure.vue';

export default defineComponent({
	components: {
		FinalResultLegals,
		EmailSubscribe,
		QuestionStructure,
		FinalDates
	},

	props: {
		question: {
			type: Object as PropType<EmailQuestion>,
			required: true
		}
	},

	setup(props) {
		const rightCol = ref<HTMLElement>();
		const data = reactive({
			wasEmailSent: false,
			colHeight: 'auto'
		});

		const dates = computed(
			() => props.question.data.calculateDates?.(questionsGetters.answersMap()) ?? []
		);

		const shouldBeDewormedNow = computed(
			() => props.question.data.shouldBeDewormedNow?.(questionsGetters.answersMap()) ?? false
		);

		const datesFlat = computed(() => {
			let datesOnly: Dayjs[] = [];

			dates.value.forEach((value: AnyObject) => {
				if (value.dates.length) {
					datesOnly = [...datesOnly, ...value.dates];
				}
			});

			return datesOnly;
		});

		const allDatesArePast = computed(() => {
			const today = dayjs();

			return datesFlat.value.every(date => {
				if (today.diff(date, 'day') > 0) {
					return true;
				}
			});
		});

		const animalType = computed(questionsState.type);

		const isCat = animalType.value === 'cat';

		const isCatOnExhibition =
			isCat && questionsState.answers().find(v => v.id === 7)?.answer === false;

		const isKitten = isCat && questionsState.answers().find(v => v.id === 1)?.answer === false;

		const catType = isKitten ? 'kitten' : isCatOnExhibition ? 'exhibition' : '';

		useRedirectOnIdle();

		return {
			...toRefs(data),

			rightCol,
			dates,
			shouldBeDewormedNow,
			allDatesArePast,
			datesFlat,
			animalType,
			catType,
			isKitten,

			showDates: computed(() => dates.value.length > 0),
			headline: computed(() => props.question.title),
			recommendations: computed(() => props.question.data?.recommendations),
			animalTypeText: computed(() => (animalType.value === 'cat' ? 'kotów' : 'psów')),
			secondLink: computed(() => {
				if (props.question.data.secondLink) {
					return props.question.data.secondLink;
				}

				return animalType.value === 'cat'
					? { link: 'https://www.dronspot.com.pl', text: 'www.dronspot.com.pl' }
					: { link: 'https://www.drontal.pl', text: 'www.drontal.pl' };
			}),
			additionalNotes: computed(() => props.question?.data?.additionalNotes)
		};
	},

	methods: {
		formatDate(date: Dayjs): string {
			return date.format('D.MM.YYYY');
		}
	}
});
