<template>
	<VLazyImage
		v-if="animalType === 'cat' && !isKitten"
		:class="`final-dates__image--${animalType}`"
		:src="require('@/assets/images/view-questions/drug-cat-3.png')"
		alt=""
		width="381"
		height="178"
	/>

	<VLazyImage
		v-else
		:class="`final-dates__image--${animalType}`"
		:src="require('@/assets/images/view-questions/drug-dog.png')"
		alt=""
		width="264"
		height="201"
	/>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { QuestionnaireType } from '@/store/modules/questions/types';

export default defineComponent({
	props: {
		animalType: {
			type: String as PropType<QuestionnaireType>,
			required: true
		},
		isKitten: {
			type: Boolean,
			required: false,
			default: false
		}
	}
});
</script>
