<template>
	<div class="boolean-question boolean-question--left">
		<BaseButton class="boolean-question__button" @click="onLeftClick">
			{{ leftData.label }}
		</BaseButton>

		<div v-if="leftData.subTitle" class="boolean-question__after-button">
			<p>{{ leftData.subTitle }}</p>
		</div>
	</div>

	<div class="boolean-question boolean-question--right">
		<BaseButton class="boolean-question__button" @click="onRightClick">
			{{ rightData.label }}
		</BaseButton>

		<div v-if="rightData.subTitle" class="boolean-question__after-button">
			<p>{{ rightData.subTitle }}</p>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { BooleanQuestion } from '@/models/QuestionModel';

export default defineComponent({
	props: {
		question: {
			type: Object as PropType<BooleanQuestion>,
			required: true
		}
	},

	emits: ['answer-selected'],

	setup(props) {
		return {
			leftData: computed(() => props.question.data.left),
			rightData: computed(() => props.question.data.right)
		};
	},

	methods: {
		onLeftClick() {
			this.$emit('answer-selected', true);
		},

		onRightClick() {
			this.$emit('answer-selected', false);
		}
	}
});
</script>

<style lang="scss">
.boolean-question {
	max-width: 211px;

	@include max-phablet {
		min-width: 50%;
		max-width: 50%;
	}

	&__button {
		width: 100%;
		text-transform: uppercase;

		@media screen and (min-width: 2000px) {
			min-width: 211px;
		}

		@include max-phablet {
			min-width: 100%;
		}
	}

	&__after-button {
		p {
			margin-top: 2.15rem;
			color: $color_cyan;
			font-size: 2.1rem;
			line-height: (24 / 21);
		}
	}
}
</style>
