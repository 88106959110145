
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Spinner'
	// components: {
	// BaseButton
	// },
	// setup(props) {}
});
