<template>
	<question-structure class="final-result" v-bind="{ headline }">
		<div ref="content" class="final-result__content">
			<div class="final-result__wrapper">
				<div class="final-result__top">
					<h3 class="final-result__headline">
						Zalecenia
					</h3>

					<h4 v-if="shouldBeDewormedNow" class="final-result__subtitle">
						ODROBACZ JAK NAJSZYBCIEJ
					</h4>

					<p
						v-if="recommendations"
						class="final-result__recommendations"
						v-html="recommendations"
					/>

					<p v-if="additionalNotes" class="final-result__additional-notes">
						{{ additionalNotes }}
					</p>

					<p class="final-result__referral">
						Poznaj szczegółowy schemat odrobaczania {{ animalTypeText }} zgodnie z rekomendacją
						ESCCAP na
						<a href="http://www.esccap.pl" target="_blank">www.esccap.pl</a>
						lub na
						<!-- eslint-disable-next-line prettier/prettier -->
						<a :href="secondLink.link" target="_blank">{{ secondLink.text }}</a>. Jednak to Twój lekarz weterynarii pomoże Ci najlepiej dopasować częstotliwość
						odrobaczania odpowiednią dla Twojego pupila.
					</p>

					<p v-if="allDatesArePast && datesFlat.length" class="final-result__note-special">
						Ups, zwęszyliśmy datę wsteczną. To znak, że powinieneś odrobaczyć pupila jak najszybciej
						i przejść ścieżkę odrobaczania ponownie!
					</p>

					<p v-if="isKitten" class="final-result__recommendations">
						Kolejne zabiegi
						<strong>co 4 tygodnie</strong>
						do 6. miesiąca życia.
					</p>
				</div>

				<final-dates class="final-result__dates" v-bind="{ animalType, isKitten, dates }" />

				<div class="final-result__email">
					<h3 class="final-result__headline">
						Wpisz swój e-mail
					</h3>

					<p class="final-result__email-description">
						<!-- eslint-disable-next-line prettier/prettier -->
						Zostaw nam swój <strong>adres email, a wyślemy Ci wynik zaleceń</strong>. Terminy odrobaczań będziesz mógł/mogła w prosty sposób dodać do swojego kalendarza.
					</p>

					<email-subscribe v-bind="{ dates, recommendations }" />
				</div>

				<div class="final-result__retry">
					<h3 class="final-result__retry-headline">
						Sprawdź, jak często odrobaczać kolejnego pupila
						<base-button inline class="final-result__retry-button" :to="{ name: 'questions' }">
							Start
						</base-button>
					</h3>
				</div>

				<final-result-legals v-bind="{ animalType, catType }" />
			</div>
		</div>
	</question-structure>
</template>

<script lang="ts">
import dayjs, { Dayjs } from 'dayjs';
import { computed, defineComponent, PropType, reactive, ref, toRefs } from 'vue';

import FinalResultLegals from '@/components/questions/FinalResultLegals.vue';
import { useRedirectOnIdle } from '@/composables';
import { EmailQuestion } from '@/models/QuestionModel';
import { questionsGetters, questionsState } from '@/store/modules/questions';
import { AnyObject } from '@/tools/types';

import FinalDates from '../final/FinalDates.vue';
import EmailSubscribe from './EmailSubscribe.vue';
import QuestionStructure from './QuestionStructure.vue';

export default defineComponent({
	components: {
		FinalResultLegals,
		EmailSubscribe,
		QuestionStructure,
		FinalDates
	},

	props: {
		question: {
			type: Object as PropType<EmailQuestion>,
			required: true
		}
	},

	setup(props) {
		const rightCol = ref<HTMLElement>();
		const data = reactive({
			wasEmailSent: false,
			colHeight: 'auto'
		});

		const dates = computed(
			() => props.question.data.calculateDates?.(questionsGetters.answersMap()) ?? []
		);

		const shouldBeDewormedNow = computed(
			() => props.question.data.shouldBeDewormedNow?.(questionsGetters.answersMap()) ?? false
		);

		const datesFlat = computed(() => {
			let datesOnly: Dayjs[] = [];

			dates.value.forEach((value: AnyObject) => {
				if (value.dates.length) {
					datesOnly = [...datesOnly, ...value.dates];
				}
			});

			return datesOnly;
		});

		const allDatesArePast = computed(() => {
			const today = dayjs();

			return datesFlat.value.every(date => {
				if (today.diff(date, 'day') > 0) {
					return true;
				}
			});
		});

		const animalType = computed(questionsState.type);

		const isCat = animalType.value === 'cat';

		const isCatOnExhibition =
			isCat && questionsState.answers().find(v => v.id === 7)?.answer === false;

		const isKitten = isCat && questionsState.answers().find(v => v.id === 1)?.answer === false;

		const catType = isKitten ? 'kitten' : isCatOnExhibition ? 'exhibition' : '';

		useRedirectOnIdle();

		return {
			...toRefs(data),

			rightCol,
			dates,
			shouldBeDewormedNow,
			allDatesArePast,
			datesFlat,
			animalType,
			catType,
			isKitten,

			showDates: computed(() => dates.value.length > 0),
			headline: computed(() => props.question.title),
			recommendations: computed(() => props.question.data?.recommendations),
			animalTypeText: computed(() => (animalType.value === 'cat' ? 'kotów' : 'psów')),
			secondLink: computed(() => {
				if (props.question.data.secondLink) {
					return props.question.data.secondLink;
				}

				return animalType.value === 'cat'
					? { link: 'https://www.dronspot.com.pl', text: 'www.dronspot.com.pl' }
					: { link: 'https://www.drontal.pl', text: 'www.drontal.pl' };
			}),
			additionalNotes: computed(() => props.question?.data?.additionalNotes)
		};
	},

	methods: {
		formatDate(date: Dayjs): string {
			return date.format('D.MM.YYYY');
		}
	}
});
</script>

<style lang="scss">
.final-result {
	width: 100%;
	max-width: 790px;
	margin: 0 auto;
	padding: 0;
	position: relative;
	z-index: $z_index_content;

	@include max-tablet {
		padding: 56px;
	}

	@include max-phablet {
		padding: 0 8px;
	}

	&__wrapper {
		padding: 1.5rem 10rem 2.5rem;
		background-color: #fff;

		@include min-2k {
			padding: 2rem 7rem 2.5rem;
		}

		@include max-tablet {
			padding: 1.5rem 8rem 2.5rem;
		}

		@include max-phablet {
			padding: 1.5rem 4rem 2.5rem;
		}
	}

	&__headline {
		@include font-din-pro;

		margin-bottom: 1rem;
		font-size: 24px;
		line-height: (31 / 24);
		text-transform: uppercase;

		@include max-phablet {
			font-size: 20px;
			line-height: (25 / 20);
		}

		&--dates {
			margin-bottom: 1rem;
		}
	}

	&__subtitle {
		@include font-din-pro;

		margin-bottom: 1rem;
		color: $color_red;
		font-size: 30px;
		line-height: (31 / 20);

		@include max-phablet {
			font-size: 18px;
			line-height: (25 / 18);
		}
	}

	&__content {
		@include flex-stretch;

		@include bp(1000) {
			display: block;
		}

		@include max-phablet {
			margin-top: -8px;
		}
	}

	&__col {
		flex: 1 0 50%;
		flex-wrap: wrap;
		width: 100%;

		&:nth-of-type(1) {
			@include flex-center-between;

			flex-direction: column;
			padding: 2.7rem 20px 3.5rem;
			background-color: rgb(255 255 255 / 80%);

			@include max-phablet {
				padding: 24px 16px 30px;
			}
		}

		&:nth-of-type(2) {
			padding: 4.7rem 97px;
			position: relative;
			color: white;
			background-color: rgba($color_cyan, 0.8);

			@include max-phablet {
				padding: 24px 16px 30px;
			}
		}
	}

	&__pre-form-text {
		margin-bottom: 3rem;
	}

	&__recommendations,
	&__referral,
	&__note-special,
	&__additional-notes,
	&__email-description {
		color: $color_brown;
		font-size: 14px;
		line-height: (16.41 / 14);
		text-align: left;
	}

	&__recommendations {
		text-align: center;

		strong {
			color: $color_green_light;
		}
	}

	&__referral {
		margin-top: 1.4rem;
		margin-bottom: 30px;
		font-size: 10px;
		text-align: left;

		a {
			color: $color_cyan;
			font-weight: bold;
			text-decoration: none;
		}
	}

	&__additional-notes,
	&__note-special {
		font-weight: bold;
	}

	&__note-special {
		margin-bottom: 1.5rem;
		text-decoration: underline;
	}

	&__additional-notes {
		margin-top: 1rem;
	}

	&__email-description {
		margin-bottom: 2rem;
		color: $color_dark;
		font-size: 16px;
		line-height: 19/16;
	}

	// &__dates {
	//	margin-bottom: 2rem;
	// }

	&__after-submit {
		position: absolute;
		top: 50%;
		left: 50%;
		font-family: DINNextLTPro, sans-serif;
		font-size: 24px;
		line-height: (31 / 24);
		text-transform: uppercase;
		transform: translate(-50%, -50%);
		pointer-events: none;

		@include max-phablet {
			font-size: 20px;
			line-height: (25 / 20);
		}
	}

	&__retry {
		@include font-jockey;

		margin-top: 1rem;
		margin-bottom: 0.5rem;
		font-size: 20px;
		font-weight: bold;
		line-height: (50 / 20);
		text-transform: uppercase;
	}

	&__retry-button {
		margin-left: 1rem;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
	}

	&__disclaimer {
		color: $color_brown;
		font-size: 7px;
		text-align: justify;
	}
}
</style>
