<template>
	<div class="spinner">
		<div class="spinner__ring"></div>
		<div class="spinner__ring"></div>
		<div class="spinner__ring"></div>
		<div class="spinner__ring"></div>
		<div class="spinner__ring"></div>
		<div class="spinner__ring"></div>
		<div class="spinner__ring"></div>
		<div class="spinner__ring"></div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Spinner'
	// components: {
	// BaseButton
	// },
	// setup(props) {}
});
</script>
<style lang="scss" scoped>
@keyframes spinner-animation {
	100% {
		transform: rotate(360deg);
	}
}

.spinner {
	width: 45px;
	height: 45px;
	padding: 2px;
	position: relative;
	overflow: hidden;

	.spinner__ring {
		margin: auto;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		animation: spinner-animation 1500ms cubic-bezier(0.68, -0.75, 0.265, 1.75) infinite forwards;
		border: 2px solid transparent;
		border-top-color: white;
		border-radius: 50%;

		&:nth-child(1) {
			width: calc(50px / 8 + 0 * 45px / 8);
			height: calc(45px / 8 + 0 * 45px / 8);
			animation-delay: calc(50ms * 1);
		}

		&:nth-child(2) {
			width: calc(45px / 8 + 1 * 45px / 8);
			height: calc(45px / 8 + 1 * 45px / 8);
			animation-delay: calc(50ms * 2);
		}

		&:nth-child(3) {
			width: calc(45px / 8 + 2 * 45px / 8);
			height: calc(45px / 8 + 2 * 45px / 8);
			animation-delay: calc(50ms * 3);
		}

		&:nth-child(4) {
			width: calc(45px / 8 + 3 * 45px / 8);
			height: calc(45px / 8 + 3 * 45px / 8);
			animation-delay: calc(50ms * 4);
		}

		&:nth-child(5) {
			width: calc(45px / 8 + 4 * 45px / 8);
			height: calc(45px / 8 + 4 * 45px / 8);
			animation-delay: calc(50ms * 5);
		}

		&:nth-child(6) {
			width: calc(45px / 8 + 5 * 45px / 8);
			height: calc(45px / 8 + 5 * 45px / 8);
			animation-delay: calc(50ms * 6);
		}

		&:nth-child(7) {
			width: calc(45px / 8 + 6 * 45px / 8);
			height: calc(45px / 8 + 6 * 45px / 8);
			animation-delay: calc(50ms * 7);
		}

		&:nth-child(8) {
			width: calc(45px / 8 + 7 * 45px / 8);
			height: calc(45px / 8 + 7 * 45px / 8);
			animation-delay: calc(50ms * 8);
		}
	}
}
</style>
