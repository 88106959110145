
import { computed, defineComponent, PropType } from 'vue';

import { BooleanQuestion } from '@/models/QuestionModel';

export default defineComponent({
	props: {
		question: {
			type: Object as PropType<BooleanQuestion>,
			required: true
		}
	},

	emits: ['answer-selected'],

	setup(props) {
		return {
			leftData: computed(() => props.question.data.left),
			rightData: computed(() => props.question.data.right)
		};
	},

	methods: {
		onLeftClick() {
			this.$emit('answer-selected', true);
		},

		onRightClick() {
			this.$emit('answer-selected', false);
		}
	}
});
