
import gsap from 'gsap';
import { Power3 } from 'gsap/all';
import { defineComponent } from 'vue';

import { mixTransition } from '@/mixins/factory';

const DURATION_IN = 0.5;
const DURATION_OUT = 0.3;

export default defineComponent({
	mixins: [mixTransition()],

	setup(props) {
		const durationIn = () => props.duration?.in ?? DURATION_IN;
		const durationOut = () => props.duration?.out ?? DURATION_OUT;

		return {
			onEnter: (el: HTMLElement, done: () => void) => {
				gsap.fromTo(
					el,
					{ height: 0 },
					{
						duration: durationIn(),
						ease: Power3.easeOut,

						height: el.scrollHeight,
						onComplete: () => {
							gsap.set(el, { height: 'auto' });
							done();
						}
					}
				);
			},

			onLeave: (el: HTMLElement, done: () => void) => {
				gsap.fromTo(
					el,
					{ height: el.scrollHeight },
					{
						duration: durationOut(),
						ease: Power3.easeOut,

						height: 0,
						onComplete: done
					}
				);
			}
		};
	}
});
