
import { defineComponent, PropType } from 'vue';

import { QuestionnaireType } from '@/store/modules/questions/types';

export default defineComponent({
	props: {
		animalType: {
			type: String as PropType<QuestionnaireType>,
			required: true
		},
		isKitten: {
			type: Boolean,
			required: false,
			default: false
		}
	}
});
