<template>
	<div class="final-dates">
		<div v-if="!dates.length && !isKitten" class="final-dates__wrapper final-dates__wrapper--empty">
			<animal-image class="final-dates__image" v-bind="{ animalType, isKitten }" />
		</div>

		<div
			v-if="!dates.length && isKitten"
			class="final-dates__wrapper final-dates__wrapper--double-images double-images"
		>
			<div class="double-images__item">
				<p class="double-images__title">
					Wybierz Drontal, jeśli Twój kociak ma
					<strong>3-8 tygodni</strong>
				</p>
				<VLazyImage
					:class="`final-dates__image--${animalType}`"
					:src="require('@/assets/images/view-final/kitty-left.png')"
					alt="Drontal"
					width="100"
					height="113"
				/>
			</div>
			<div class="double-images__item">
				<p class="double-images__title">
					Wybierz Dronspot, jeśli Twój kociak ma co
					<strong>najmniej 8 tygodni</strong>
				</p>
				<VLazyImage
					:class="`final-dates__image--${animalType}`"
					:src="require('@/assets/images/view-final/kitty-packshots-right.png')"
					alt="Dronspot packshots"
					width="229"
					height="105"
				/>
			</div>
		</div>

		<div v-if="dates.length === 1" class="final-dates__wrapper final-dates__wrapper--single">
			<h3 class="final-dates__headline">
				{{ dates[0].headline }}
			</h3>

			<ul v-if="dates[0].dates" class="final-dates__dates-column">
				<li v-for="(date, i) in dates[0].dates" :key="i" class="final-dates__single-date">
					{{ formatDate(date) }}
				</li>

				<animal-image class="final-dates__image" v-bind="{ animalType, isKitten }" />
			</ul>
		</div>

		<div v-if="dates.length === 2" class="final-dates__wrapper final-dates__wrapper--double">
			<div class="final-dates__dates">
				<div v-for="(row, i) in dates" :key="i" class="final-dates__row">
					<h3 class="final-dates__headline" v-html="row.headline" />

					<ul v-if="row.dates" class="final-dates__dates-column">
						<li v-for="(date, j) in row.dates" :key="j" class="final-dates__single-date">
							{{ formatDate(date) }}
						</li>
					</ul>
				</div>
			</div>

			<animal-image class="final-dates__image" v-bind="{ animalType, isKitten }" />
		</div>
	</div>
</template>

<script lang="ts">
import { Dayjs } from 'dayjs';
import { computed, defineComponent, PropType } from 'vue';

import { AnswerColumn } from '@/models/AnswerModel';
import { questionsState } from '@/store/modules/questions';

import AnimalImage from './AnimalImage.vue';

export default defineComponent({
	components: { AnimalImage },
	props: {
		dates: {
			type: Array as PropType<AnswerColumn[]>,
			required: true
		},
		isKitten: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	setup() {
		const animalType = computed(questionsState.type);

		return {
			animalType
		};
	},

	methods: {
		formatDate(date: Dayjs): string {
			return date.format('D.MM.YYYY');
		}
	}
});
</script>

<style lang="scss">
.final-dates {
	$self: &;

	&__wrapper {
		&--double {
			display: grid;
			grid-template-columns: 1fr 250px;
			align-items: center;
		}

		&--double-images {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 1.5em;

			@include max-phablet {
				flex-direction: column;
			}
		}
	}

	&__headline {
		@include font-din-pro;

		margin-bottom: 2.4rem;
		color: $color_brown;
		font-size: 16px;
		font-weight: 600;
		line-height: (31 / 24);
		text-transform: uppercase;

		@include max-phablet {
			font-size: 20px;
			line-height: (25 / 20);
		}

		#{$self}__wrapper--double & {
			text-align: left;
		}
	}

	&__dates {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
	}

	&__dates-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 5rem;
	}

	&__row {
		display: grid;
		grid-template-columns: 1fr 13rem;
		gap: 1.5rem;
	}

	&__dates-column {
		display: flex;
		flex-direction: column;
		min-height: 120px;
		position: relative;
		color: $color_brown;
		font-size: 16px;
		font-weight: 600;
		line-height: (23 / 20);
		text-align: center;

		// margin-left: 10rem;
		// padding-right: 20rem;

		@include max-tablet {
			min-height: 180px;
			margin-left: 7rem;
			padding-right: 0;
		}

		@include max-phablet {
			margin-left: 8rem;
		}

		@include max-mobile {
			margin-left: 0;
		}

		#{$self}__wrapper--double & {
			min-height: 0;
			margin-left: 0;
			padding-right: 0;
		}
	}

	&__single-date {
		display: block;
		padding: 0 0 0 15px;
		position: relative;

		// &::before {
		//	content: '';
		//	width: 4px;
		//	height: 4px;
		//	position: absolute;
		//	top: 0.5em;
		//	left: 0;
		//	border-radius: 50%;
		//	background: currentColor;
		// }
	}

	&__image {
		position: absolute;
		top: 0;

		&--cat {
			max-width: 229px;
			right: 3px;
		}

		&--dog {
			max-width: 244px;
			top: -30px;
			right: -70px;

			@include max-tablet {
				top: 0;
				right: 0;
			}
		}

		#{$self}__wrapper--empty & {
			position: static;
			top: unset;
			right: unset;
			bottom: unset;
			left: unset;

			&--cat {
				margin-bottom: -2rem;
			}

			&--dog {
				margin-bottom: -3rem;
			}
		}

		#{$self}__wrapper--double & {
			position: static;
			top: unset;
			right: unset;
			bottom: unset;
			left: unset;

			&--cat {
				max-width: 310px;
				margin-right: -60px;
			}

			&--dog {
				max-width: 270px;
			}
		}
	}
}

.double-images {
	&__item {
		max-width: 230px;
	}

	&__title {
		margin: 0 3em;
		color: $color_brown;
		font-size: 12px;
		font-weight: normal;

		strong {
			color: $color_cyan;
		}
	}
}
</style>
