
import { Dayjs } from 'dayjs';
import { computed, defineComponent, PropType } from 'vue';

import { AnswerColumn } from '@/models/AnswerModel';
import { questionsState } from '@/store/modules/questions';

import AnimalImage from './AnimalImage.vue';

export default defineComponent({
	components: { AnimalImage },
	props: {
		dates: {
			type: Array as PropType<AnswerColumn[]>,
			required: true
		},
		isKitten: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	setup() {
		const animalType = computed(questionsState.type);

		return {
			animalType
		};
	},

	methods: {
		formatDate(date: Dayjs): string {
			return date.format('D.MM.YYYY');
		}
	}
});
